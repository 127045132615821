.gnrt-img-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.gnrt-img-navbar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.gnrt-img-navbar img {
    width: 24px;
    height: 24px;
    margin-left: 20px;
}

.gnrt-img-navbar-title {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.gnrt-img-navbar-topic {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.gnrt-img-navbar-close {
    margin-left: auto; 
    margin-right: 20px;
}

.gnrt-img-navbar-close img {
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.gnrt-img-main {
    height: calc(100vh - 38px);
    display: flex;
}

.gnrt-img-main-sidebar {
    width: 350px;
    overflow-y: auto;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 20px;
}

.gnrt-img-main-sidebar button {
    width: 350px;
    height: 48px;
    flex-shrink: 0;
    background: none;
    border-radius: 5px;
    border: 1px solid #B4307D;
    color: #B4307D;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
    cursor: pointer;
}

.gnrt-img-main-sidebar-item {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
}

.gnrt-img-main-sidebar-item-prompt {
    width: 318px;
    height: 50px;
    resize: none;
    padding: 10px 15px 15px 15px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
}

.gnrt-img-main-sidebar-item-negative-prompt {
    width: 318px;
    height: 40px;
    resize: none;
    padding: 10px 15px 15px 15px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
}

.gnrt-img-main-sidebar-item .toggle-btn {
    transition: transform .3s;
}

.gnrt-img-main-sidebar-item.open .gnrt-img-main-sidebar-item-title .toggle-btn {
    transform: rotate(180deg);
}

.gnrt-img-main-sidebar-item-title {
    display: flex;
    width: fit-content;
    justify-content: space-between;
    margin-bottom: 12px;
    cursor: pointer;
}

.gnrt-img-main-sidebar-item-title i {
    margin-right: 14px;
}

.gnrt-img-main-sidebar-aspect-ratio {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
}

.gnrt-img-main-sidebar-aspect-ratio-div {
    width: 81px;
    height: 42px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid;
    border-color: rgba(153, 153, 153, 0.30);
    cursor: pointer;
}

.gnrt-img-main-sidebar-aspect-ratio-div-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.gnrt-img-main-sidebar-styles {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 3px;
}

.gnrt-img-main-sidebar-styles-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 2px;
}

.gnrt-img-main-sidebar-styles-item-img-initial {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    width: 65px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.3);
    cursor: pointer;
}

.gnrt-img-main-sidebar-styles-item-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    width: 65px;
    border-radius: 5px;
}

.gnrt-img-main-sidebar-styles-item-img img {
    min-height: 100%;
    min-width: 100%;
    border-radius: 5px;
    cursor: pointer;
}

.gnrt-img-main-sidebar-image-count input[type=range] {
    width: 100%;
    height: 2px;
    accent-color: #B4307D;
}

.gnrt-img-main-display {
    flex: 1;
    /* width: calc(100vw - 430px); */
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 39px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* overflow: auto; */

    overflow-y: auto;
}

.gnrt-img-main-display-header {
    display: flex;
    align-items: center;
    color: #999;
}

.gnrt-img-main-display-header-hr {
    height: 1px;
    flex: 1;
    border-radius: 5px;
    margin-left: 41px;
    background: rgba(153, 153, 153, 0.30);
}

.gnrt-img-main-display-header-prompt {
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gnrt-img-main-display-header-img {
    display: flex;
    overflow-x: scroll;
    margin-top: 14px;
    margin-bottom: 38px;
}

.gnrt-img-main-display-header-img-container {
    width: 356px;
    height: 356px;
    margin-right: 20px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.gnrt-img-main-display-header-img-container img {
    max-width: 100%; /* Set the maximum width to the width of the container */
    max-height: 100%; /* Set the maximum height to the height of the container */
    display: block; /* Remove any extra space around the image */
    margin: 0 auto;
    border-radius: 4px;
}

.gnrt-img-main-display-header-img-container-dwn-rmv {
    position: absolute;
    display: flex;
    right: 0;
    bottom: 0;
    margin: 20px;
}

.popup-deleter-content {
    width: 354px;
    height: 251px;
    position: relative;
    background-color: #FFF;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
}

.popup-deleter-content-txt {
    margin: 45px;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.popup-deleter-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    margin: 16px;
}

.popup-deleter-buttons button {
    width: 156px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    color: #999;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: none;
    cursor: pointer;
}

.popup-generate-image-view-content {
    width: calc(100vw*1729/1920);
    height: calc(100vh*1300/1531);
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #FFF;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
}

.popup-generate-image-view-content-helper {
    display: flex;
    margin-top: calc(100vh*19/1531);
}

.popup-generate-image-view-content-left {
    width: calc(100vw*400/1920);
    height: calc(100vh*1179/1531);
    margin-left: calc(100vw*20/1920);
    margin-right: calc(100vw*20/1920);
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow: auto;
}

.popup-generate-image-view-content-left-container {
    margin-left: calc(100vw*20/1920);
    margin-right: calc(100vw*20/1920);
}

.popup-generate-image-view-content-left-title {
    margin-top: calc(100vh*20/822);
}

.popup-generate-image-view-content-left-body {
    margin-top: calc(100vh*10/822);
    margin-bottom: calc(100vh*20/822);
}

.popup-generate-image-view-content-left-buttons {
    display: flex;
    margin-top: calc(100vh*20/822);
    margin-bottom: 30px;
}

.popup-generate-image-view-content-left-buttons img {
    cursor: pointer;
}

.popup-generate-image-view-content-right {
    flex: 1;
    height: calc(100vh*1179/1531);
    margin-right: calc(100vw*20/1920);
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    position: relative;
    display: flex;
    justify-content: center;
}

.popup-generate-image-view-content-right-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-generate-image-view-content-right-wrapper img, video {
    max-height: 100%;
    max-width: 100%;
    border-radius: 4px;
}

.popup-generate-image-view-content-right-slide-container {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    margin-left: calc(100vw*20/1920);
    margin-right: calc(100vw*20/1920);
    cursor: pointer;
}

.gnrt-img-main-sidebar-mobile {
    display: none;
    position: absolute;
    width: 100vw;
    height: calc(100vh);
    background: #fff;
    margin-top: -39px;
}

.gnrt-img-main-sidebar-mobile-img {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 24px;
    transform: rotate(180deg);
}

.gnrt-img-main-sidebar-mobile-img-wrapper {
    display: flex;
    width: calc(100vw - 40px);
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.gnrt-img-main-sidebar-mobile-btn {
    margin-bottom: 30px;
    width: calc(100vw - 40px);
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #B4307D;
    color: #B4307D;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: none;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.gnrt-img-main-sidebar-mobile-content {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 44px);
    margin-left: 20px;
    margin-right: 20px;
}

.gnrt-img-main-sidebar-mobile-nav {
    display: none;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background: rgba(153, 153, 153, 0.50);
    margin: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.gnrt-img-main-sidebar-mobile-nav img {
    width: 30px;
    padding-bottom: 4px;
}

.gnrt-img-main-display-header-img-mobile {
    display: none;
}

.gnrt-img-mobile-img-viewer-wrapper {
    width: 100%;
    /* height: 390px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gnrt-img-mobile-img-viewer-wrapper-img {
    width: calc(100vw - 4px);
    height: calc(100vw - 4px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 20px;
    border: 1px solid rgba(153, 153, 153, 0.30);
}

.gnrt-img-mobile-img-viewer-wrapper-img img {
    max-height: calc(100vw - 4px);
    max-width: calc(100vw - 4px);

}

.gnrt-img-mobile-img-viewer-wrapper-help {
    display: flex;
    justify-content: center;
}

.gnrt-img-mobile-img-viewer-wrapper-data {
    width: 100%;
    margin-bottom: 60px;
}

@media screen and (max-width: 1100px) {
    .gnrt-img-container {
        overflow-y: auto;
    }

    .gnrt-img-main-display {
        height: fit-content;
        overflow-y: hidden;
    }

    .gnrt-img-main-sidebar {
        display: none;
    }

    .gnrt-img-navbar-topic {
        display: none;
    }

    .gnrt-img-main-sidebar {
        width: 350px;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        padding: 20px;
    }

    .gnrt-img-main-sidebar-mobile-nav {
        display: flex;
    }

    .gnrt-img-main-sidebar-mobile {
        /* position: absolute; */
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .gnrt-img-main-sidebar-item-prompt {
        /* margin-left: 20px; */
        width: calc(100vw - 76px);
    }

    .gnrt-img-main-sidebar-item-negative-prompt {
        width: calc(100vw - 76px);
        height: 50px;
    }
    
    .gnrt-img-main-sidebar-styles-item-img-initial {
        width: calc((100vw - 76px)/5);
        height: calc((100vw - 76px)/5);
    }

    .gnrt-img-main-sidebar-styles-item-img-initial img {
        width: calc((100vw - 76px)/11);;
    }
    
    .gnrt-img-main-sidebar-styles-item-img {
        width: calc((100vw - 76px)/5);
        height: calc((100vw - 76px)/5);
    }

    .gnrt-img-main-sidebar-aspect-ratio-div {
        width: calc((100vw - 76px)/4);
        height: calc((100vw - 76px)*3/20);
    }

    .gnrt-img-main-display-header-prompt {
        display: none;
    }

    .gnrt-img-main-display-header-img {
        display: none;
    }

    .gnrt-img-main-display-header-img-mobile {
        display: block;
    }

    .gnrt-img-main-sidebar-image-count {
        margin-bottom: 10px;
    }

}