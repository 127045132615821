/* deployment */

@font-face {
  font-family: 'Inter';
  src: url(/public/assets/fonts/Inter-ExtraLight.ttf);
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: url(/public/assets/fonts/Inter-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url(/public/assets/fonts/Inter-Medium.ttf);
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url(/public/assets/fonts/Inter-Bold.ttf);
  font-weight: 900;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Inter';
  text-decoration: none;
}

input:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.email-activation {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  transition: width 0.3s ease; /* Add a transition for smooth animation */
  display: none;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(153, 153, 153, 0.30);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(153, 153, 153);
}

a {
  color: #000;
  text-decoration: none
}

a.active {
  color: #B4307D;
}

.hr {
  margin: 0;
  padding: 0;
  background-color: #E6E6E6;
  height: 1px;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid rgba(0, 0, 0, 0.3);;
  animation: prixClipFix 2s linear infinite ;
}

.loader::after{
  transform: rotate3d(90, 90, 0, 180deg );
  border-color: #B4307D;
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}
