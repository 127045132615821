.home-div1 {
    /* background-image: url(/src/images/home-div1.jpeg); */

    /* Full height */
    height: 100vh;
    min-height: 500px;
    width: 100%;
    position: relative;

    /* Center and scale the image nicely */
    /* background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; */
    z-index: 1;
}

.home-div1-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* opacity: 0.8; */
}

.home-navbar {
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 100%; */
    height: 109px;
    padding-left: 80px;
    padding-right: 80px;
    border-bottom: solid rgba(255, 255, 255, 0.30) 1px;
    position: relative;
    z-index: 3;
}

.home-navbar img {
    cursor: pointer;
}

.home-navbar-left-web {
    display: flex;
    align-items: center;
}

.home-navbar-left-web a {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 78px;
}

.home-navbar-left-web a.active {
    color: #B4307D;
}

.home-navbar-left-mobile {
    display: none;
}

.home-navbar-left-mobile-sidebar {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    min-height: 500px;
    width: calc(100vw*280/390);
    background: #000;
    z-index: 3;
}

.home-navbar-left-mobile-sidebar-button {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
    margin-bottom: calc(100vh * 97 / 844);
}

.home-navbar-left-mobile-sidebar-button-link {
    height: 60px;
    width: calc(100vw*280/390 - 36px);
    border-radius: 5px;
    border: 1px solid #FFF;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-navbar-left-mobile-sidebar button {
    height: 60px;
    width: calc(100vw*280/390 - 36px);
    border-radius: 5px;
    border: 1px solid #FFF;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: none;
    justify-content: flex-end;
    
}

.home-navbar-left-mobile-sidebar-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
    /* width: 100%; */
    height: 109px;
    border-bottom: solid rgba(255, 255, 255, 0.30) 1px;
    position: relative;
    z-index: 3;
}

.home-navbar-left-mobile-sidebar-navlinks {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 26px;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-navbar-left-mobile-sidebar-navlinks a {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-login-btn {
    width: 109px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #FFF;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* margin-right: 24px; */
    cursor: pointer;
}

.home-container {
    /* background: yellowgreen; */
    margin-left: calc(100vw * 354/1920);
    margin-right: calc(100vw * 354/1920);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 0;
}

.home-div1-txt1 {
    color: #FFF;
    text-align: center;
    font-size: calc(100vw * 82/1920);
    font-style: normal;
    font-weight: 700;
    line-height: calc(100vw * 132/1920); /* 146.667% */
    letter-spacing: 1.8px;
}

.home-div1-txt2 {
    margin-top: 32px;
    color: #FFF;
    text-align: center;
    font-size: calc(100vw * 25/1920);
    font-style: normal;
    font-weight: 700;
    line-height: 35px; /* 140% */
    letter-spacing: 0.5px;
}

.home-body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-body-container-wrapper {
    margin-left: calc(100vw * 354/1920);
    margin-right: calc(100vw * 354/1920);
    width: 100%;
}

.home-body-container {
    margin-left: calc(100vw * 354/1920);
    margin-right: calc(100vw * 354/1920);
    width: 100%;
}

.home-div2 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.home-div2-grids {
    /* border-top:    1px solid  rgba(153, 153, 153, 0.30); */
    height: 191px;
    border-right:  0.5px solid rgba(153, 153, 153, 0.30);
    border-bottom: 1px solid rgba(153, 153, 153, 0.30);
    border-left:   0.5px solid  rgba(153, 153, 153, 0.30);
    display: flex;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: calc(100vw/4 * 111/479);
    position: relative;
}

.home-div2-grids:hover {
    color: #B4307D;
    cursor: pointer;
}

.home-div2-grids-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home-div2-grids-wrapper span {
    margin-top: 10px;
    /* text-decoration: underline; */
}

.home-div2-grids-wrapper-title {
    font-size: 24px;
    font-weight: 700;
    margin-top: 2px;
}

.home-body-container-intro {
    margin-top: 130px;
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
}

.home-body-container-intro button {
    cursor: pointer;
    width: 351px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #000;
    background: #FFF;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 130px;
    margin-bottom: 130px;
}

.home-body-container-video-main {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-body-container-video-main video {
    /* width: calc(100vw - 712px) !important;
    height: calc((100vw - 712px)*0.6) !important; */
    width: 100%;
    margin-bottom: 110px;
}

.home-body-container-video-title {
    display: flex;
    align-items: center;
    margin-top: 100px;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 210% */
}

.home-body-container-video-title-vl {
    width: 1px;
    height: 40px;
    background: #000;
    margin-left: 20px;
    margin-right: 20px;
}

.home-body-container-video-title-body {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
}

.home-body-container-download {
    margin-top: 120px;
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px; /* 125% */
}

.home-body-container-download-btn {
    display: flex;
    margin-top: 60px;
    margin-bottom: 90px;
}

.home-body-container-download-btn button {
    cursor: pointer;
    width: 192px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #B4307D;
    background: #FFF;
    color: #B4307D;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home-body-hr {
    height: 1px;
    width: 100%;
    /* width: calc(100vw - 4px); */
    border-radius: 100px;
    background: rgba(153, 153, 153, 0.30);
    margin: 60px;
}

.home-body-container-grids-main {
    margin-top: 40px;
}

.home-body-container-grids-main-title {
    color: #999;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 50% */
}

.home-body-container-grids-main-body {
    margin-top: 40px;
    color: #000;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 142.857% */
}

.home-body-img-paginator {
    margin-top: 140px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 10px;
}

.home-body-img-paginator img {
    width: calc(100vw/5.1)
}

.home-body-container-info-grids {
    margin-top: 64px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 39px;
    width: 100%;
}

.home-body-container-info-grids-fill {
    width: 100%;
    position: relative;
}

.home-body-container-info-grids-fill video {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.home-body-container-info-grids-fill img {
    width: 100%;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
}

.home-body-container-info-grids-title {
    margin-top: 30px;
    color: #000;
    font-size: calc(100vw * 30/1920);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-body-container-info-grids-title span {
    color: #B4307D;
}

.home-body-container-info-grids-body-txt {
    color: #000;
    font-size: calc(100vw * 18/1920);
    font-style: normal;
    font-weight: 400;
    margin-top: 20px;
    line-height: 28px;
}

.home-body-container-info-iter {
    margin-top: 100px;
    margin-bottom: 140px;
}

.home-body-container-info-iter-title {
    color: #000;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-body-container-info-iter-body {
    margin-top: 42px;
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 165.523%;
}

.home-body-container-service-intro {
    margin-top: 126px;
    margin-bottom: 192px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-body-container-service-intro-title-1 {
    color: #000;
    text-align: center;
    font-size: calc(100vw * 40/1920);
    font-style: normal;
    font-weight: 700;
    line-height: 137.523%; /* 22.004px */
}

.home-body-container-service-intro-title-2 {
    /* font-size: 40px; */
    margin-top: 10px;
    font-style: normal;
    font-size: calc(100vw * 65/1920);
    font-weight: 700;
    line-height: 137.523%;
}

.home-body-container-info-grids-fill-img-txt {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: calc(100vw * 50 / 1920);
}

.home-body-container-info-grids-fill-img-txt-title {
    color: #FFF;
    text-align: right;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.50);
    font-size: calc(100vw * 42 / 1920);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-body-container-info-grids-fill-img-txt-body {
    margin-top: 20px;
    color: #FFF;
    text-align: right;
    font-size: calc(100vw * 22 / 1920);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-footer-img-container {
    background-image: url(/src/images/home-footer.jpg);
    /* background-image: url(/src/images/home-footer.svg); */

    /* Full height */
    height: 75vh;
    min-height: 300px;
    width: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-footer-img-container-txt {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-size: calc(100vw * 90/1920);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-footer-container {
    margin-left: calc(100vw * 354/1920);
    margin-right: calc(100vw * 354/1920);
    position: absolute;
    display: flex;
}

.home-footer-info-container {
    display: flex;
    align-items: center;
    height: 25vh;
    width: 100vw;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
}

.home-footer-info-container img {
    width: 143px;
    height: 30px;
    cursor: pointer;
}

.home-footer-info-container-txt {
    margin-left: calc(100vw * 161/1920);
}

.overlay-main-page {
    width: 100vw;
    height: 100vh;
}

.product-dropdown-item {
    font-size: 16px;
    color: #FFF;
    margin-top: 20px;
    cursor: pointer;
}

.product-dropdown-item:hover {
    color: #B4307D;
}


.dropdown-btn-mobile {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
}

@media screen and (max-width: 1100px) {
    .product-dropdown-item {
        font-size: 18px;
    }

    .home-navbar-left-web {
        display: none;
    }

    .home-navbar-left-mobile {
        display: flex;
    }

    .home-navbar-left-mobile i {
        cursor: pointer;
    }

    .home-container {
        margin-left: 30px;
        margin-right: 30px;
    }

    .home-div1-txt1 {
        font-size: 38px;
        line-height: 45px;
        letter-spacing: 0.8px;
        margin-top: 50px
    }

    .home-div1-txt2 {
        font-size: 17px;
        font-weight: 500;
        line-height: 25px; /* 146.667% */
        letter-spacing: 0.36px;
    }

    .home-div2 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .home-body-container {
        margin-left: 30px;
        margin-right: 30px;
    }
    
    .home-body-container-video-main video {
        width: 100% !important;
        height: fit-content !important;
    }

    .home-navbar {
        padding-left: 35px;
        padding-right: 35px;
    }

    .home-body-container-info-grids {
        grid-template-columns: repeat(1, 1fr);
    }

    .home-body-container-info-grids-fill-img-txt-title {
        font-size: calc(100vw * 22/390);
    }

    .home-body-container-info-grids-fill-img-txt-body {
        font-size: calc(100vw * 14/390);
        text-align: center;
    }

    .home-body-container-video-main {
        margin-top: 30px;
    }

    .home-body-container-info-grids-title {
        font-size: calc(100vw * 22/390);
    }
    
    .home-body-container-info-grids-body-txt {
        font-size: calc(100vw * 15/390);
        line-height: calc(100vw * 24/390);
    }

    .home-body-img-paginator {
        margin-top: 140px;
        width: 100vw;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 2px;
    }

    .home-body-img-paginator-hide {
        display: none;
    }
    
    .home-body-img-paginator img {
        width: 100%;
    }

    .home-body-container-service-intro-title-1 {
        font-size: calc(100vw * 16/390);
    }

    .home-body-container-service-intro-title-2 {
        font-size: calc(100vw * 22/390);
        text-align: center;
    }

    .home-body-container-info-grids-fill-img-txt {
        position: absolute;
        bottom: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        right: 0;
        left: 0;
    }

    .home-body-container-intro {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        margin-top: 80px;
    }

    .home-body-container-intro button {
        margin-top: 70px;
        margin-bottom: 80px;
        width: 100%;
    }

    .home-body-container-video-title-txt {
        color: #000;
        font-size: calc(100vw * 18/390);
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 122.222% */
        line-height: calc(100vw * 22/390);
    }

    .home-body-container-grids-main-title {
        color: #999;
        font-size: calc(100vw * 26/390);
        font-style: normal;
        font-weight: 700;
        line-height: calc(100vw * 25/390); /* 96.154% */
    }

    .home-body-container-grids-main-body {
        color: #000;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 113.636% */
    }

    .home-div2-grids {
        height: 135px;
        padding-left: calc(100vw * 50/390);
    }

    .home-body-container-video-title-body {
        font-size: calc(100vw * 30/390);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.5px;
    }

    .home-body-container-download {
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
    }

    .home-footer-container {
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 200px;
        flex-direction: column;
        align-items: baseline;
        
    }

    .home-footer-img-container-txt {
        margin-right: 35px;
        margin-left: 35px;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .home-footer-info-container {
        flex-direction: column;
        align-items: baseline;
        min-height: 150px;
        padding-bottom: 260px;
        
    }

    .home-footer-info-container img {
        margin-top: 73px;
        margin-bottom: 30px;
        /* margin-left: 35px; */
    }

    .home-footer-info-container-txt {
        margin-right: 65px;
        margin-bottom: 60px;
        margin-left: 35px;
    }

    .overlay-main-page {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(0,0,0,0.3);
        z-index: 2;
    }

}