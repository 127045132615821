.posts-container {
    width: calc(100vw - 4px);
    max-height: 100vh;
    overflow-y: auto;
}

.posts-gallery {
    /* max-width: 1500px; */
    width: calc(100vw - 4px);
    margin: 0 auto;
    display: grid;
    grid-gap: 3px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 250px;
    grid-auto-flow: dense;
}

.posts-gallery div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.posts-gallery-v-stretch {
    grid-row: span 2;
}

.posts-gallery-h-stretch {
    grid-column: span 2;
}

.posts-gallery-big-stretch {
    grid-row: span 2;
    grid-column: span 2;
}

.posts-navbar {
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 100%; */
    height: 109px;
    padding-left: 80px;
    padding-right: 80px;
    border-bottom: solid rgba(153, 153, 153, 0.1) 1px;
    position: relative;
    z-index: 3;
}

.posts-navbar img {
    cursor: pointer;
}

.posts-navbar-left-web {
    display: flex;
    align-items: center;
}

.posts-navbar-left-web a {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: calc(100vw * 78/1920);
}

.posts-navbar-left-web a.active {
    color: #B4307D;
}

.posts-info {
    width: calc(100vw - 4px);
    height: fit-content;
    padding-bottom: 70px;
    background: #FDDC00;
}

.posts-info-title {
    color: #000;
    font-size: calc(100vw * 120/1920);
    font-style: normal;
    font-weight: 400;
    line-height: calc(100vw * 140/1920);
    letter-spacing: calc(100vw * 3.6/1920) * -1;
    padding-top: calc(100vw * 70/1920);
    padding-left: calc(100vw * 100/1920);
    padding-right: calc(100vw * 100/1920);
}

.posts-info-we-do {
    color: #000;
    font-size: calc(100vw * 25/1920);
    font-style: normal;
    font-weight: 200;
    line-height: calc(100vw * 45/1920);
    letter-spacing: calc(100vw * 0.75/1920) * -1;
    padding-top: calc(100vw * 150/1920);
    padding-left: calc(100vw * 100/1920);
    padding-right: calc(100vw * 100/1920);
}

.posts-info-we-do-info {
    color: #000;
    font-size: calc(100vw * 25/1920);
    font-style: normal;
    font-weight: 400;
    line-height: calc(100vw * 45/1920);
    letter-spacing: calc(100vw * 0.75/1920) * -1;
    padding-left: calc(100vw * 100/1920);
    padding-right: calc(100vw * 100/1920);
}

.posts-search-container {
    width: 100%;
    height: 80px;
    display: flex;
    border: 1px solid #999;
}

.posts-search-container-textarea {
    width: calc(100% / 4);
    height: 80px;
    display: flex;
    position: relative;
    background: violet;
}

.posts-search-container-textarea input {
    width: 100%;
    height: 60px;
    padding: 10px;
    text-align: center;
    font-size: 22px;
    border: none;
}

.posts-search-container-textarea input::placeholder {
    color: rgba(153, 153, 153, 0.30);
}

.posts-search-container-textarea-title {
    position: absolute;
    margin: 10px;
    font-size: 14px;
}

.posts-search-container-btn {
    width: 10%;
    height: 80px;
    border-left: none;
    cursor: pointer;
    background: rgba(153, 153, 153, 0.30);
    display: flex;
    align-items: center;
    justify-content: center;
}

.posts-search-container-btn img {
    font-size: calc(100vw * 41/1920);
}

.home-my-page a {
    margin-right: 0;
}

.post-image-viewer-overlay {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.post-image-viewer-content {
    width: calc(100vw*1729/1920);
    height: calc(100vh*1300/1531);
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #FFF;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
}

.post-image-viewer-content-navbar-mobile {
    background: #FFF;
    height: 55px;
    /* overflow: auto; */
    position: fixed;
    border-bottom: 1px solid rgba(153, 153, 153, 0.30);
    width: 100vw;
    display: flex;
    align-items: center;
}

.post-image-viewer-content-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 15px;
}

.post-image-viewer-content-navbar-title {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.post-image-view-content-helper {
    display: flex;
    overflow: hidden;
    padding-top: 4px;
    padding-bottom: 4px;
    height: 100%;
    margin-top: calc(100vh*19/1531);
    margin-bottom: 16px;
    position: relative;
}

.post-image-view-content-left {
    width: 400px;
    height: calc(100%);
    margin-left: 20px;
    /* padding: 20px; */
    margin-right: calc(100vw*20/1920);
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    flex-direction: column;
}

.post-image-view-content-left-scroll {
    height: calc(100% - 68px);
    overflow-y: auto;
}

.post-image-view-content-left-avatar {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
}

.post-image-view-content-left-avatar-img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    pointer-events: none;
}

.post-image-view-content-left-avatar-info {
    width: 100%;
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.post-image-view-content-left-avatar-info-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px;
    position: relative;
}

.post-image-view-content-left-avatar-info-content span {
    font-weight: 700;
}

.post-image-view-content-left-username {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.post-image-view-content-left-avatar-info-social img {
    width: 20px;
    height: 20px;
    margin-right: 20px;
}

.post-image-view-content-left-title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.post-image-view-content-left-switch-page-btn {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
}

.post-image-view-content-left-switch-page-btn-div {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(153, 153, 153, 0.30);
    padding-bottom: 10px;
    cursor: pointer;
}

.post-image-view-content-left-summary {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 15px;
    white-space: pre-line;
}

.post-image-view-content-left-image-display {
    position: relative;
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1px;
    margin-bottom: 20px;
}

.post-image-view-content-left-image-display-div img, video{
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
}

.post-image-view-content-left-image-display-div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    aspect-ratio: 1/1;
    border-radius: 4px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    cursor: pointer;
}

.post-image-view-content-left-switch-post-btn {
    background: #FFF;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.post-image-view-content-left-switch-post-btn button {
    width: 177px;
    height: 42px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    background: #FFF;
    color: #999;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    margin-bottom: 12px;
}

.post-image-view-content-right {
    flex: 1;
    height: 100%;
    margin-right: 20px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    position: relative;
    display: flex;
    justify-content: center;
}

.post-image-viewer-content-mobile-file-viewer {
    margin-top: 41px;
}

.post-image-viewer-content-mobile-file-viewer-wrapper {
    position: relative;
    width: 100vw;
}

.post-image-viewer-content-mobile-file-viewer-wrapper img, video {
    width: 100%;
}

@media screen and (max-width: 1100px) {

    .posts-info {
        height: calc(100vw * 487/390);
    }

    .posts-info-title {
        font-size: calc(100vw * 38/390);
        padding-top: calc(100vw * 60/390);
        padding-left: 20px;
        padding-right: 20px;
        font-weight: 500;
        line-height: calc(100vw * 40/390);
        letter-spacing: calc(100vw * 1.14/390) * -1;
    }

    .posts-info-we-do {
        font-size: calc(100vw * 25/390);
        line-height: calc(100vw * 15/390);
        letter-spacing: calc(100vw * 0.75/390) * -1;
        padding-top: calc(100vw * 70/390);
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .posts-info-we-do-info {
        font-size: calc(100vw * 20/390);
        font-weight: 300;
        line-height: calc(100vw * 25/390);
        letter-spacing: calc(100vw * 0.6/390) * -1;
        padding-top: calc(100vw * 21/390);
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .post-image-viewer-overlay {
        display: flex;
    }
    
    .post-image-viewer-content {
        position: fixed;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #FFF;
        z-index: 100;
    }

    .post-image-view-content-helper {
        display: flex;
        overflow: hidden;
        padding-top: 0px;
        padding-bottom: 4px;
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
        position: relative;
    }

    .post-image-view-content-left {
        width: 100vw;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 0px;
        border: none;
        flex-direction: column-reverse;
    }

    .posts-navbar-left-web {
        display: none;
    }

    .posts-navbar {
        padding-left: 22px;
        padding-right: 22px;
    }

    .post-image-view-content-left-avatar-info-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 15px;
    }

    .post-image-view-content-left-switch-post-btn {
        width: calc(100% - 40px);
    }
    
    .post-image-view-content-left-switch-post-btn button {
        width: calc(50% - 4px);
    }
}

@media(max-width: 560px) {
    .posts-gallery-v-stretch {
        grid-row: span 1;
    }
    
    .posts-gallery-h-stretch {
        grid-column: span 1;
    }
    
    .posts-gallery-big-stretch {
        grid-row: span 1;
        grid-column: span 1;
    }
}