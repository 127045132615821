.bible-admin-container {
    width: 100%;
    display: flex;
    margin-top: 10px;
}

.bible-admin-split {
    width: 50%;
    position: relative;
}

.bible-admin-split textarea {
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    resize: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.bible-admin-draganddrop-div {
    border-radius: 5px;
    border: 1px solid #000;
}

.bible-admin-split-reset-btn {
    border: 1px solid #B4307D;
    color: #B4307D;
    background: #FFF;
    border-radius: 5px;
    width: 50px;
    height: 20px;
    cursor: pointer;
}

.bible-admin-upload {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

.bible-admin-draganddrop-div-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(89px, 1fr));
    max-height: 325px;
    overflow-y: scroll;
    grid-gap: 2px;
}

.bible-admin-drag-and-drop-div {
    width: 100%;
    height: calc(100% - 2px);
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.bible-admin-uploaded-div {
    /* flex-shrink: 0; */
    width: 89px;
    height: 89px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    display: flex;
    align-items: center;
    justify-content: center;
}

.bible-admin-uploaded-div img, video {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}

.bible-admin-upload-div {
    aspect-ratio: 1/1;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.bible-admin-upload-div img {
    width: calc(100vw * 52/1920);
}

.bible-admin-categories {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
}

.bible-admin-categories-item {
    min-width: 80px;
    max-width: 113px;
    height: 42px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.bible-admin-styles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
}

.bible-admin-styles-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 2px;
    cursor: pointer;
}

.bible-admin-styles-item img {
    width: 100%;
    border-radius: 5px;
}

.bible-admin-styles-item-img-initial {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    display: flex;
    align-items: center;
    justify-content: center;
}

.bible-admin-styles-item-img-initial img {
    width: calc(100% - 36px);
    height: calc(100% - 36px);
}

.bible-admin-aspect-ratio {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
}

.bible-admin-aspect-ratio-item {
    width: 100%;
    height: 84px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.bible-admin-save-btn {
    width: 257px;
    height: 60px;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    border: 1px solid #B4307D;
    background: #FFF;
    color: #B4307D;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.gnrt-img-main-sidebar-item-model-selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: -3px;
}

.gnrt-img-main-sidebar-item-model-selection-button {
    width: 173px;
    height: 42px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}


.admin-post table {
    width: 100%;
    border-spacing: 0;
}

.admin-post th {
    padding: 4px;
    background-color: rgba(166, 166, 166, 0.2);
    border-left: 0.5px solid rgba(166, 166, 166, 0.4);
    border-right: 0.5px solid rgba(166, 166, 166, 0.4);
    color: #A6A6A6;
    font-size: 16px;
}

.admin-post td {
    text-align: center;
    border-left: 0.5px solid rgba(166, 166, 166, 0);
    border-right: 0.5px solid rgba(166, 166, 166, 0);
    padding: 4px;
}

.table-row {
    cursor: pointer;
}

.table-row:hover {
    background-color: rgba(201, 150, 195, 0.3); /* Change this to the desired hover color */
}

.admin-table-wrapper {
    height: 500px;
    overflow: auto;
    border: 1px solid rgba(166, 166, 166, 0.3);
    border-radius: 5px;
}

.admin-table-wrapper::-webkit-scrollbar {
    width: 4px;
    transition: width 0.3s ease; /* Add a transition for smooth animation */
    display: block;
}

.admin-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.admin-popup-content {
    width: 354px;
    height: 251px;
    position: relative;
    background-color: #FFF;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
}