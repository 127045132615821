.dropdown {
    width: 71px;
    height: 40px;
    user-select: none;
    position: relative;
    border-radius: 5px;
    border: 1px solid #FFF;
}

.dropdown .dropdown-btn {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-left: 19px;
    padding-right: 9px;
}

.dropdown .dropdown-btn .toggle-btn {
    transform: rotate(180deg);
}

.dropdown .dropdown-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: 2px;
    color: #333;
    width: 71px;
    height: 69px;
    border-radius: 5px;
    background: #FFF;
}

.dropdown .dropdown-content .dropdown-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: all 0.2s;
    text-transform: uppercase;
    width: 71px;
    height: 34px;
    border-radius: 5px;
}

/* .dropdown .dropdown-content .dropdown-item:hover {
    background: #f4f4f4;
} */