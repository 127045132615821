.my-page-main {
    padding-right: 20px;
    margin-top: 18px;
}

.my-page-main-fill {
    width: 100%;
    position: relative;
}

.my-page-main-fill img {
    width: 100%;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
}

.my-page-main-fill-1 {
    width: 100%;
    position: relative;
}

.my-page-main-fill-1 img {
    width: 100%;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
}

.my-page-main-fill-1-txt {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: calc(100vw * 35/1920);
    margin-bottom: calc(100vw * 35/1920);
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: calc(100vw * 32/1920);;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.my-page-main-title {
    margin-top: 50px;
    margin-bottom: 15px;
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.my-page-main-title-parent {
    margin-top: 30px;
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

.my-page-main-img-title {
    position: absolute;
    bottom: 24px;
    left: 24px;
    color: #FFF;
    text-shadow: 0px 0px 4px #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.my-page-main-image-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
}

.my-page-main-fill-title {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
    margin-top: 15px;
    margin-bottom: 15px;
}

.my-page-footer {
    background: rgba(153, 153, 153, 0.1);
    height: 100px;
    margin-top: 130px;
    display: flex;
    align-items: center;
    position: relative;
}

.my-page-footer-content {
    display: flex;
    position: absolute;
    right: 0;
    margin-right: calc(100vw * 100/1920);
}

.my-page-footer-content-item {
    margin-left: calc(100vw * 20/1920);
    text-decoration: underline;
    text-underline-offset: 3.5px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    text-decoration-line: underline;
    cursor: pointer;
}

@media screen and (max-width: 1100px) {
    .my-page-main {
        padding-left: 20px;
        margin-top: 0px;
        overflow-x: hidden;
    }

    .my-page-main-image-data {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 16px;
    }
    
    .my-page-main-fill-1 {
        width: 100%;
        position: relative;
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .my-page-main-fill-1 img {
        width: fit-content;
        height: calc(100vw * 144/390);
        margin-left: -40px;
        border-radius: 0px;
    }

    .my-page-main-fill-1-txt {
        margin-left: calc(100vw * 25/390);
        margin-bottom: calc(100vw * 15/390);
        font-size: calc(100vw * 18/390);
    }

    .my-page-footer {
        justify-content: center;
        margin-top: 100px;
    }

    .my-page-footer-content {
        position: relative;
        margin-right: 0;
    }
    
    .my-page-footer-content-item {
        margin-left: calc(100vw * 10/390);
        text-underline-offset: 5px;
        font-size: calc(100vw * 12/390);
    }
}