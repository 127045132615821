.account-popup {
    position: absolute;
    width: 242px;
    height: 282px;
    flex-shrink: 0;
    margin-top: 10px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    z-index: 100;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.account-popup-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    color: #000;
}

.account-popup-info a {
    color: #000;
}
.account-popup-info img {
    width: 40px;
    height: 40px;
    border-radius: 100px;
}

.account-popup-info-username {
    font-size: 14px;
    font-weight: 700;
    margin-top: 5px;
}

.account-popup-info-email {
    font-size: 12px;
    margin-top: 5px;
}

.account-popup-info-account-btn {
    width: 191px;
    height: 38px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 17px;
}

.account-popup-info-account-btn-div1 {
    font-size: 14px;
}

.account-popup-info-account-btn-div2 {
    color: #999;
    font-size: 10px;
    margin-top: 1.5px;
    cursor: pointer;
}

.account-popup-info-upgrade-btn {
    width: 210px;
    height: 40px;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 5px;
    background: #B4307D;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 14px;
}

.account-popup-info-hr {
    width: 210px;
    height: 2px;
    flex-shrink: 0;
    border-radius: 100px;
    background: rgba(153, 153, 153, 0.30);
    margin-top: 15px;
}

.account-popup-logout-btn {
    margin-top: 15px;
    margin-left: 32px;
    color: #000;
    font-size: 14px;
    cursor: pointer;
    width: fit-content;
}

.account-settings-container {
    margin-top: 47px;
    margin-right: 20px;
}

.account-settings-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.account-settings-navbar-title {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.account-settings-navbar-btn button {
    width: 143px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #000;
    color: #000;
    font-size: 16px;
    background: none;
    margin-left: 14px;
    cursor: pointer;
}

.account-settings-body {
    padding-bottom: 20px;
    min-height: 876px;
    height: fit-content;
    flex-shrink: 0;
    border-radius: 5px;
    margin-top: 35px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    margin-bottom: 20px;
}

.account-settings-body-container {
    margin-top: 32px;
    margin-left: 28px;
}

.account-settings-body-navbar {
    display: flex;
    margin-bottom: 20px;
}

.account-settings-body-navbar-btn {
    cursor: pointer;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.account-settings-component {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.account-settings-component input {
    width: 244px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    background: #FFF;
    padding-left: 28px;
    padding-right: 28px;
}

.account-settings-component-profile {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.account-settings-component-profile img {
    width: 80px;
    height: 80px;
    border-radius: 100px;
}

.account-settings-component-profile button {
    width: 194px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    background: none;
    margin-left: 26px;
    color: #999;
    cursor: pointer;
}

.account-settings-component-profile-info {
    color: #999;
    font-size: 12px;
    margin-top: 20px;
}

.account-settings-component-profile-input {
    margin-top: 20px;
    margin-bottom: 16px;
}

.account-settings-subscription-component {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.account-settings-subscription-component-container {
    width: calc(100% - 30px);
    height: 100%;
    margin-top: 30px;
    display: flex;
}

.account-settings-subscription-component-credits-div {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 420px;
    height: 422px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    border-radius: 5px;
    font-size: 24px;
}

.account-settings-subscription-component-credits-div-table {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.account-settings-subscription-component-credits-div-table-1 {
    width: 160px;
}

.account-settings-subscription-component-credits-div-table-2 {
    width: 150px;
}

.account-settings-subscription-component-credits-div-table-3 {
    width: 130px;
}

.account-settings-subscription-component-credits-div-table-cancel-btn {
    width: 70px;
    height: 27px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.3);
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.account-settings-subscription-component-credits-div-right-title {
    font-size: 30px;
    font-weight: bold;
}

.account-settings-subscription-component-credits-div-right-title-info {
    font-size: 18px;
    font-weight: bold;
}

.account-settings-subscription-component-payment-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 22px;
}

.account-settings-subscription-component-payment-div span {
    color: #B4307D;
}

.account-settings-subscription-component-payment-type-div {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
}

.account-settings-subscription-component-payment-div input[type="checkbox"] {
    appearance: none;
    background-color: #fff;
    width: 27px;
    height: 27px;
    border: 1px solid #A6A6A6;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

.account-settings-subscription-component-payment-div input[type="checkbox"]:hover {
    cursor: pointer;
}

.account-settings-subscription-component-payment-div input[type="checkbox"]::before {
    content: "";
    width: 17px;
    height: 17px;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
}

.account-settings-subscription-component-payment-div input[type="checkbox"]:checked::before {
    transform: scale(1);
    background: #B4307D;
}

@media screen and (max-width: 1100px) {
    .account-settings-navbar-title {
        margin-left: 30px;
    }

    .account-settings-body {
        margin-left: 20px;
    }

    .account-settings-navbar {
        flex-direction: column-reverse;
        align-items: start;
    }

    .account-settings-navbar-title {
        margin-top: 30px;
        margin-left: 20px;
    }

    .account-settings-navbar-btn {
        margin-left: 20px;
        width: calc(100% - 20px);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .account-settings-navbar-btn button {
        width: 49%;
        height: 40px;
        margin-left: 0;
    }

    .account-settings-subscription-component-container {
        flex-direction: column;
    }

    .account-settings-subscription-component-credits-div {
        width: calc(100% + 30px);
    }

    .account-settings-subscription-component-credits-div-right-title {
        margin-top: 30px;
    }

    .account-settings-subscription-component-payment-div {
        font-size: 15px;
    }
}