.rmv-bg-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.rmv-bg-navbar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background: #FFF;
}

.rmv-bg-navbar img {
    width: 24px;
    height: 24px;
    margin-left: 20px;
}

.rmv-bg-navbar-title {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.rmv-bg-navbar-topic {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.rmv-bg-navbar-close {
    margin-left: auto; 
    margin-right: 22px;
}

.rmv-bg-navbar-close img {
    cursor: pointer;
    width: 14px;
    height: 14px;
}

.rmv-bg-main {
    height: calc(100vh - 38px);
    display: flex;
}

.rmv-bg-main-sidebar {
    width: 350px;
    background: #fff;
    overflow-y: scroll;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 20px;
}

.rmv-bg-main-sidebar-upload-btn {
    height: 66px;
    border-radius: 5px;
    border: 1px solid #B4307D;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #B4307D;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.rmv-bg-main-sidebar-item {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
}

.rmv-bg-main-sidebar-item .toggle-btn {
    transition: transform .3s;
}

.rmv-bg-main-sidebar-item.open .rmv-bg-main-sidebar-item-title .toggle-btn {
    transform: rotate(180deg);
}

.rmv-bg-main-sidebar-item-title {
    margin-bottom: 12px;
    width: fit-content;
    cursor: pointer;
}

.rmv-bg-main-sidebar-item-title i {
    margin-right: 14px;
}

.rmv-bg-main-sidebar-tool-1 {
    /* width: 350px; */
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    display: flex;
    justify-content: center;
    align-items: center;
}

.rmv-bg-main-sidebar-tool-2 {
    width: 170.5px;
    margin-top: 5px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    display: flex;
    justify-content: center;
    align-items: center;
}

.rmv-bg-main-sidebar-colors {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 4px;
}

.rmv-bg-main-sidebar-colors li {
    width: 66px;
    height: 66px;
    border-radius: 5px;
    list-style: none;
    cursor: pointer;
}

.rmv-bg-main-sidebar-color-picker {
    display: flex;
    margin-top: 5px;
}

.rmv-bg-main-sidebar-color-picker-display {
    width: 242px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;
    background: url(/src/images/img-picker/transparent-picker.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid rgba(153, 153, 153, 0.30);
}

.rmv-bg-main-sidebar-color-picker-display-img {
    margin-left: auto;
    margin-right: -1px;
    width: 32px;
    height: 42px;
    flex-shrink: 0;
    border-radius: 0px 5px 5px 0px;
    background: rgba(153, 153, 153, 0.60);
    margin-top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rmv-bg-main-sidebar-color-picker-input {
    width: 99px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    color: #000;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* text-transform: uppercase; */
}

.rmv-bg-main-drag-and-drop {
    flex: 1;
    overflow-y: auto;
}

.rmv-bg-main-drag-and-drop-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 20px 0px 20px;
}

.rmv-bg-main-drag-and-drop-navbar-links {
    color: #999;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
}

.rmv-bg-main-drag-and-drop-navbar-links-active {
    color: #B4307D;
    font-weight: bold;
}

.rmv-bg-main-drag-and-drop-zone {
    height: calc(100vh - 112px);
    flex-shrink: 0;
    margin: 5px 20px 20px 20px;
    border-radius: 4px;
    border: 1px solid rgba(153, 153, 153, 0.30);
}

.rmv-bg-main-drag-and-drop-main {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rmv-bg-main-drag-and-drop-main-img {
    width: 70px;
    height: 70px;
    cursor: pointer;
}

.rmv-bg-main-drag-and-drop-main-txt {
    margin-top: 9px;
    color: #B4307D;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.rmv-bg-main-drag-and-drop-main-display {
    display: flex;
    position: relative;
}

.rmv-bg-main-drag-and-drop-main-display-preview-main {
    width: 50%;
    height: calc(100vh - 112px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rmv-bg-main-drag-and-drop-main-display-preview {
    width: 50%;
    height: calc(100vh - 112px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rmv-bg-main-drag-and-drop-main-display-preview-container {
    display: flex;
    align-items: center;
    position: center center;
    max-width: 100%;
    max-height: 100%; 
    background-image: url(/src/images/img-picker/img-bg-picker/transparent-img-bg.jpg);
    background-size: 100% auto;
}

.rmv-bg-main-drag-and-drop-main-display-preview-container img {
    max-width: 100%;
    max-height: 100%;
}

.rmv-bg-history-container {
    margin-left: 20px;
    margin-right: 20px;
}

.rmv-bg-history-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #999;
    margin-top: 12px;
}

.rmv-bg-history-navbar-mobile {
    display: none;
}

.rmv-bg-history-navbar-right {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.rmv-bg-history-navbar-right input {
    /* width: 244px; */
    height: 40px;
    width: calc(100vw*244/1920);
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    background: #FFF;
    padding-left: 28px;
    padding-right: 28px;
    /* color: #999; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 16px;
}

.rmv-bg-history-navbar-right-date-chooser {
    display: flex;
    align-items: center;
    margin-right: calc(100vw * 40/1920);
}

.rmv-bg-history-navbar-right-date-chooser input {
    width: calc(100vw*92/1920);
}

.rmv-bg-history-navbar-right button {
    /* width: 115px; */
    width: calc(100vw*115/1920);
    height: 40px;
    border-radius: 5px;
    border: 1px solid #B4307D;
    background: #B4307D;
    margin-right: calc(100vw*42/1920);
}

.rmv-bg-history-navbar-right button i {
    font-size: 20px;
    color: #FFF;
}

.rmv-bg-history-navbar-filter i {
    margin-left: 4px;
    color: #D9D9D9 ;
    text-align: center;
    transform: translateY(-15%);
}

.rmv-bg-history-table {
    margin-top: 14px;
    border-radius: 4px 4px 4px 4px;
    /* min-height: 842px; */
    height: fit-content;
    border: 1px solid rgba(153, 153, 153, 0.30);
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.rmv-bg-history-table table {
    width: 100%;
    border-spacing: 0;
    position: relative;
    border-collapse: collapse;
}

.rmv-bg-history-table th {
    padding: 4px;
    background: rgba(153, 153, 153, 0.10);
    color: #999;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    /* border-right: 1px solid black; */
}



.rmv-bg-history-table th:after {
    content: '';
    height: 24px; /*You can change this if you want smaller/bigger borders */
    width: 2px;
    border-radius: 100px;
    position: absolute;
    right: 0;
    top: 18%; /* // If you want to set a smaller height and center it, change this value */
    
    background-color: rgba(153, 153, 153, 0.30);; /* // The color of your border */
}

.rmv-bg-history-table th:last-child:after {
    width: 0px;
}

.rmv-bg-history-table td {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 16px;
    padding-bottom: 14px;
}

.rmv-bg-history-table-hr {
    position: absolute;
    width: 96%;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    border-radius: 100px;
    background: rgba(153, 153, 153, 0.15);
}

.rmv-bg-history-table-item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
}

.rmv-bg-history-table-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 93px;
    height: 93px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
}

.rmv-bg-history-table-img {
    display: flex;
    align-items: center;
    position: center center;
    background-size: 100% auto;
    background-image: url(/src/images/img-picker/img-bg-picker/transparent-img-bg.jpg);
}

.rmv-bg-history-table-img img{
    max-width: 93px;
    max-height: 93px;
}

.popup-downloader-content {
    width: 354px;
    height: 405px;
    position: relative;
    background-color: #FFF;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.popup-downloader-header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
}

.popup-downloader-content input {
    width: 287px;
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    color: #999;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.popup-downloader-resolution {
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popup-downloader-selector {
    width: 78px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.popup-downloader-selector-active {
    width: 78px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #B4307D;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #B4307D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.popup-downloader-content button {
    margin-left: 16px;
    margin-right: 16px;
    width: 319px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #B4307D;
    background: none;
    color: #B4307D;
    cursor: pointer;
}

input[type="checkbox"] {
    appearance: none;
    background-color: #fff;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    border-radius: 5px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}
  
input[type="checkbox"]:hover {
    cursor: pointer;
}
  
input[type="checkbox"]::before {
    content: "";
    width: 22px;
    height: 22px;
    border-radius: 4px;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
}
  
input[type="checkbox"]:checked::before {
    transform: scale(1);
    background: #B4307D;
}

.rmv-bg-history-table-paginator {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 18px;
}

.rmv-bg-main-drag-and-drop-main-display-preview-mobile-btns {
    display: none;
}

.rmv-bg-main-drag-and-drop-main-display-preview-btns {
    display: none;
}

@media screen and (max-width: 1100px) {
    .rmv-bg-navbar-title {
        font-size: 16px;
    }
    
    .rmv-bg-navbar-topic {
        display: none;
    }

    .rmv-bg-main-drag-and-drop-navbar-links {
        color: #999;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
    }

    .rmv-bg-main-drag-and-drop-navbar-btns {
        display: none;
    }

    .rmv-bg-container {
        width: 100vw;
        height: fit-content;
    }

    .rmv-bg-main {
        width: 100vw;
        height: fit-content;
        display: flex;
        flex-direction: column-reverse;
    }

    .rmv-bg-main-sidebar {
        height: fit-content;
        width: calc(100vw - 40px);
        background: #fff;
        transition: all 0s;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
    }

    .rmv-bg-main-sidebar-colors li {
        width: calc((100vw - 60px)/5);
        height: calc((100vw - 60px)/5);
    }

    .rmv-bg-main-sidebar-colors img {
        width: calc((100vw - 60px)/5);
        height: calc((100vw - 60px)/5);
    }

    .rmv-bg-main-sidebar-color-picker-display {
        width: calc(70vw - 30px);
        height: calc((70vw - 27px)*40/245);
        flex-shrink: 0;
        border-radius: 5px;
        border: 1px solid rgba(153, 153, 153, 0.30);
        background: #D482E1;
        margin-right: 5px;
        cursor: pointer;
    }

    .rmv-bg-main-sidebar-color-picker-input {
        width: calc((70vw - 45px)*3/7);
        height: calc((70vw - 27px)*40/245);
        text-align: center;
        font-size: 14px;
    }

    .rmv-bg-main-sidebar-color-picker-display-img {
        width: calc(((70vw - 27px)*40/245 + 2px)*0.8);
        height: calc((70vw - 27px)*40/245 + 2px);
    }

    .rmv-bg-main-sidebar-color-picker-display-img img {
        width: calc(((70vw - 27px)*40/245 + 2px)*0.5);
        height: calc(((70vw - 27px)*40/245 + 2px)*0.5);
    }

    .rmv-bg-main-drag-and-drop-main {
        height: 60vh;
    }

    .rmv-bg-main-drag-and-drop-main-img {
        width: 40px;
        height: 40px;
    }

    .rmv-bg-main-drag-and-drop-zone {
        height: 60vh;
    }

    .rmv-bg-main-drag-and-drop-main-display-preview-main {
        display: none;
    }

    .rmv-bg-main-drag-and-drop-main-display-preview {
        height: 60vh;
        width: 100%;
    }

    .rmv-bg-main-drag-and-drop-main-display-preview-btns {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: 20px;
    }

    .rmv-bg-main-drag-and-drop-main-display-preview-btns img {
        width: 30px;
        height: 30px;
    }

    .rmv-bg-history-navbar {
        display: none;
    }

    .rmv-bg-history-navbar-mobile {
        display: block;
    }

    .rmv-bg-history-navbar-right {
        margin-top: 14px;
        justify-content: space-between;
        font-size: 16px;
    }

    .rmv-bg-history-navbar-right input {
        /* width: 244px; */
        width: calc(100vw*165/390);
    }

    .rmv-bg-history-navbar-right button {
        width: calc(100vw*46/390);
    }

    .rmv-bg-history-navbar-mobile-right {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .rmv-bg-history-navbar-filter {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #999;
    }

    .rmv-bg-history-table {
        border: none;
    }

    .rmv-bg-history-table-mobile-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #999;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .rmv-bg-history-table-mobile-header-hr {
        height: 1px;
        flex: 1;
        margin-left: 28px;
        border-radius: 100px;
        background: rgba(153, 153, 153, 0.30);
    }

    .rmv-bg-history-table-mobile-body {
        display: flex;
        justify-content: space-between;
        margin-top: 14px;
        margin-bottom: 18px;
    }

    .rmv-bg-history-table-mobile-body-name {
        margin: 15px;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .rmv-bg-history-table-img img{
        max-width: 140px;
        max-height: 140px;
    }

    .rmv-bg-history-table-item {
        /* display: flex;
        align-items: center;
        justify-content: center;
        min-width: 60px; */
        min-width: 60px;
    }

    .rmv-bg-history-table-img-wrapper {
        width: 140px;
        height: 140px;
    }

    .rmv-bg-main-drag-and-drop-main-display-preview-mobile-btns {
        display: block;
        position: absolute;
    }

}