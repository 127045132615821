.login {
    display: flex;
}

.login-image {
    width: 50%;
    float: left;
    height: 100vh;
}

.login-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-right: 1px solid rgba(153, 153, 153, 0.30);
}

.login-form {
    width: 50%;
    float: right;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-logo {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 30px;
    margin-right: 30px;
}

.login-form-welcome {
    display: flex;
    justify-content: center;
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 60px */
    text-align: center;
    margin-left: -80px;
    margin-right: -80px;
}

.login-form-signup {
    display: flex;
    justify-content: center;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.login-form-signup span {
    color: #B4307D;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    cursor: pointer;
}

.login-form-wrapper {
    width: 400px;
}

.login-form-container {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.input-alert-form {
    color: red;
    margin-top: 3px;
    padding: 3px;
    font-size: 14px;
}

.input-with-alert {
    display: flex;
    flex-direction: column;
}

.input-with-alert span {
    color: red;
    margin-top: 3px;
    padding: 3px;
    font-size: 14px;
    display: none;
}

.input-with-alert input:invalid ~ span {
    display: block;
}

.login-form-container input {
    height: 60px;
    margin-top: 12px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #999;
    background: #FFF;
    color: #999;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding-left: 30px;
    padding-right: 30px;
}



.login-form-container button {
    height: 60px;
    flex-shrink: 0;
    margin-top: 10px;
    border-radius: 5px;
    background: #B4307D;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    border: none;
    cursor: pointer;
}

.login-form-help {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.login-form-login-methods {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.login-form-login-methods button {
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #999;
    background: #FFF;
    margin-top: 12px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
    cursor: pointer;
}

.login-form-agreement {
    width: 100%;
    margin-top: 12px;
    color: #999;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 16.9px */
}

.login-form-agreement span {
    color: rgba(180, 48, 125, 0.50);
    cursor: pointer;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.login-form-agreement span:hover {
    text-decoration: underline;
}

.register-progress-bar {
    margin-top: 16px;
    width: 10px;
    height: 10px;
    background: #D9D9D9;
    border-radius: 10px;
    margin-left: 4px;
    margin-right: 4px;
}

.register-progress-bar.active {
    background: #F99842;
}

.register-form-confirm {
    color: #000;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 60px */
}

.register-form-confirm-navigate {
    margin-top: 70px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.register-form-confirm-navigate a {
    color: #B4307D;
    font-weight: bold;
}

.loader-login-wrapper {
    width: 50%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
}
  
.loader-login {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    /* position: absolute; */
    animation: rotate 1s linear infinite
}
  
.loader-login::before , .loader-login::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid rgba(0, 0, 0, 0.3);
    animation: prixClipFix 2s linear infinite ;
}
.loader-login::after{
    transform: rotate3d(90, 90, 0, 180deg );
    border-color: #B4307D;
}

@keyframes rotate {
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
  }
  
  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  }

@media screen and (max-width: 1100px) {
    .login-image {
        display: none;
    }
    
    .login-form {
        width: 100%;
    }

    .login-logo {
        position: relative;
        margin: 0;
        margin-bottom: 30px;
    }

    .loader-login-wrapper {
        width: 100%;
    }

    .login-form-welcome {
        margin-left: 0px;
        margin-right: 0px;
    }

    .login-form-wrapper {
        width: calc(100vw - 60px);
        padding-left: 30px;
        padding-right: 30px;
    }

}