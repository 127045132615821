.popup-color-picker-grid {
    display: flex;
    margin-top: 10px;
}
.popup-color-picker-grid span{
    width: 40px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-color-picker-grid-sign {
    width: 26px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-color-picker-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-color-picker-content {
    position: relative;
    width: 700px;
    height: 420px;
    background-color: #FFF;
    z-index: 100;
}

.popup-color-title {
    color: #B4307D;
    font-size: 20px;
    margin-left: 32px;
    margin-top: 21px;
    margin-bottom: 21px;
}

.color-picker-container {
    display: flex;
    margin-left: 27px;
    margin-top: 12px;
}

.color-picker-saturation {
    width: 309px;
    height: 309px;
    position: relative;
}

.color-picker-hue {
    margin-left: 10px;
    width: 27px;
    height: 309px;
    position: relative;
}

.color-picker-config {
    height: 309px;
    width: 281px;
    margin-left: 19px;
    border: 1px solid rgba(166, 166, 166, 0.30);
}

.color-picker-checkboard {
    height: 50px;
    width: 100px;
}

.color-picker-btn-save {
    width: 100px;
    height: 34px;
    background: none;
    color: #B4307D;
    border: 2px solid #B4307D;
    border-radius: 90px;
    cursor: pointer;
    font-size: 18px;
    margin-right: 6px;
}

.color-picker-btn-cancel {
    width: 100px;
    height: 34px;
    background: none;
    color: #A6A6A6;
    border: 2px solid #A6A6A6;
    border-radius: 90px;
    cursor: pointer;
    font-size: 18px;
    margin-top: 10px;
}

.color-picker-hue-mobile {
    display: none;
}

.color-picker-config-mobile {
    display: none;
}

@media screen and (max-width: 1100px) {
    .popup-color-picker-content {
        position: relative;
        width: 354px;
        height: 493px;
        background-color: #FFF;
    }

    .color-picker-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 9px;
    }

    .popup-color-title {
        margin-left: 10px;
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .color-picker-saturation {
        width: 335px;
        height: 169px;
        position: relative;
    }

    .color-picker-hue {
        display: none;
    }

    .color-picker-hue-mobile {
        display: block;
        margin-top: 10px;
        width: 335px;
        height: 27px;
        position: relative;
    }

    .color-picker-config {
        display: none;
    }

    .color-picker-config-mobile {
        display: block;
        left: 0;
    }

    .color-picker-config-mobile-container {
        width: 335px;
    }

    .color-picker-checkboard {
        height: 40px;
        width: 80px;
    }

    .popup-color-picker-grid {
        display: flex;
        margin-top: 8px;
    }
    .popup-color-picker-grid span{
        font-size: 12px;
        width: 37px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .popup-color-picker-grid-sign {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .color-picker-mobile-btns {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }

    .color-picker-mobile-btns button {
        width: 164px;
        height: 48px;
        color: #B4307D;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: none;
        border-radius: 5px;
        border: 1px solid #B4307D;
        cursor: pointer;
    }
}
