.sidebar-main {
    width: 100%;
    overflow-y: auto;
    z-index: 1;
    margin-left: 20px;
}

.sidebar {
    min-height: 100vh;
    min-width: 285px;
    background: #fff;
    transition: all 0.5s;
    z-index: 3;
    overflow-y: auto;
}

.sidebar-container {
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
}

.sidebar-profile {
    margin-top: 18px;
    height: 100px;
    width: 240px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    background: #FFF;
    position: relative;
}

.sidebar-profile-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.sidebar-profile img {
    height: 50px;
    width: 50px;
    margin-left: 15px;
}

.sidebar-login {
    margin-left: 12px;
}

.sidebar-settings-div {
    height: 30px;
    margin-top: 10px;
    border-radius: 0px 0px 4px 4px;
    background: rgba(153, 153, 153, 0.10);
    color: #999;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sidebar-profile-username {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-overflow: ellipsis;
}

.sidebar-profile-email {
    margin-top: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sidebar-navs {
    margin-top: 10px;
    height: 700px;
    width: 240px;
    border-radius: 5px;
    border: 1px solid rgba(153, 153, 153, 0.30);
    background: #FFF;
    /* padding-left: 41px;
    padding-right: 41px; */
    padding-top: 10px;
}

.sidebar-navs a.active {
    color: #000;
}

.sidebar-title {
    display: flex;
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 15px;
    margin-left: 15px;
    cursor: pointer;
    z-index: 10;
}


.sidebar-title .toggle-btn {
    cursor: pointer;
    transition: transform .3s;
}

.sidebar-title-parent {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.sidebar-title-subparent {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sidebar-item.open .sidebar-title .toggle-btn {
    transform: rotate(180deg);
}

.sidebar-title-img-wrapper {
    width: fit-content;
    width: 26px;
    display: flex;
    align-items: center;
}
  
.sidebar-content {
    display: flex;
    flex-direction: column;
    padding-top: 0.25em;
    height: 0;
    overflow: hidden ;
}

.sidebar-content-item {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 40px;
    margin-top: 10px;
}
  
.sidebar-item.open .sidebar-content {
    height: auto;
}
  
.top-section {
    display: none;
    padding: 20px;
    z-index: 3;
}
  
.low-section {
    display: flex;
    height: 100vh;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar-menu-icon {
    display: none;
}

.sidebar-download {
    width: 240px;
    margin-top: 8px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.sidebar-download-btn {
    width: 115px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #B4307D;
    background: #FFF;
    color: #B4307D;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sidebar-info {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.sidebar-info-price-btn {
    cursor: pointer;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    margin-top: 20px;
    background: #B4307D;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    border: none;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sidebar-navs-logo {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 20px;
}

.overlay {
    display: flex;
}

@media screen and (max-width: 1100px) {
    .top-section {
        display: flex;
        position: fixed;
        width: 100vw;
    }

    .sidebar-main {
        margin-top: 72px;
        margin-left: 0px;
    }

    .sidebar-main.open {
        overflow-y: none;
    }
    
    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 280px;
        overflow-y: auto;
    }
    
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(111,111,111, 0.6);
        z-index: 2;
    }
    
}